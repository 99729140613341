export const Assets = {
  images: {
    companyLogo: require("../../images/png/droidLogo.png"),
    companyBanner: require("../../images/png/droid banner.png"),
    comapanyAboutImage1: require("../../images/png/aboutimage1.png"),
    companyLogoNoBg: require("../../images/png/droidLogo-nobg.png"),
    coding: require("../../images/png/coding.png"),
    statistics: require("../../images/png/statistics.png"),
    ourServices: require("../../images/png/our services.png"),
    tour: require("../../images/png/travel-6493621_640.png"),
    staffBg: require("../../images/png/allstaff.png"),
    mobiledev: require("../../images/png/mobile developer.png"),
    softwaredesign: require("../../images/png/software-design.png"),
    webdesign: require("../../images/png/web design d.png"),
    customercare: require("../../images/png/customer care.png"),
    background1: require("../../images/png/background1.jpg"),
    background2: require("../../images/png/Digital-experience.png"),
    manLearning: require("../../images/png/Man-learning-online.jpg"),
    contactarealogo: require("../../images/png/i-removebg-preview.png"),
    ccare: require("../../images/png/ccare.png"),
    software: require("../../images/png/image.png"),

    stellaimg: require("../../images/png/stellaImage.jpeg"),
    uzoimg: require("../../images/png/Uzoimage.png"),
    faithimg: require("../../images/png/faithimg.png"),

    tech: require("../../images/png/tech.jpg"),
    tech2: require("../../images/png/tech2.webp"),
    tech3: require("../../images/png/tech3.webp"),
    softDev: require("../../images/png/softd.jpg"),
    softDev2: require("../../images/png/soft2.avif"),
    service: require("../../images/png/service2.jpg"),
    softwareBg: require("../../images/png/sd.png"),
    homeBg: require("../../images/png/homepage.png"),

    droid_web: require("../../images/png/D'roid_Web.png"),
    reign: require("../../images/png/reign.png"),
    one_one: require("../../images/png/one-on-one.png"),
    uni: require("../../images/png/university.png"),
    highSchool: require("../../images/png/highschool.png"),

    servicesMain: require("../../images/png/what.png"),
    techT: require("../../images/png/techtraining.png"),

    testImage: require("../../images/png/testimage.png"),
    wDev: require("../../images/png/wdev.png"),
    mDev: require("../../images/png/mdev.png"),
    tService: require("../../images/png/tservice.png"),
    backEndDev: require("../../images/png/bedev.png"),
    cReviews: require("../../images/png/creview.png"),
    appAi: require("../../images/png/ogoo-Ai.png"),

    trainingOne: require("../../images/png/train1.png"),
    trainingTwo: require("../../images/png/train2.png"),
    trainingThree: require("../../images/png/train3.png"),
    noChat: require("../../images/png/no-chat.png"),

    maximuspage: require("../../images/png/Maximuspage.jpg"),
    drizzle: require("../../images/png/drizzlepage.jpg"),
    cashBasket: require("../../images/png/newcashbasket.png"),
    knowledgecity: require("../../images/png/knowledgecityscreenshot.png"),
    dome: require("../../images/png/dome.png"),
  },
  colors: {
    basic: "#000000",
    light: "#FFFFFF",
    primary: "#282A94",
    secondary: "#479BE8",
    tertiary: "#FF6F61",
    overlay: "#00000080",
    borderColor: "#CCCCCC",
    paragraph: "#BAB8B8",
    backgroundColor: "#F9F9F9",
    substitute: "#FBCC34",

    // dark
    darkbasic: "#FFFFFF",
    darklight: "#000000",
    darkprimary: "#FFFFFF",
    darksecondary: "#A6D4FA",
    darktertiary: "#FFAEB9",
    darkoverlay: "#FFFFFF80",
    darkborderColor: "#555555",
    darkparagraph: "#CCCCCC",
    darkbackgroundColor: "#000000",
    darksubstitute: "#FBCC34",
  },
  text: {
    companyName: "D'roid Technologies International",
    companyShortName: "D'roid Technologies",
    companyInitials: "DTI",
    modalTitle: "This is the Modal Heading.",
    modalBody: "This is the Body.",
    appVersion: "v3.5",
  },
};
